import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import customTheme from './theme';
import React from 'react';
import DocumentUpload from './DocumentUpload';
import Chat from './Chat';

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <DocumentUpload />
          </Route>
          <Route path="/chat/:documentId">
            <Chat />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
