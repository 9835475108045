import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container'; 
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';


const apiUrl = process.env.REACT_APP_API_URL;
const messageApiUrl = `${apiUrl}/api/documents/question`;

function Chat() {
  const { documentId } = useParams();
  const [messages, setMessages] = useState([]);
  const [lastMessageID, setLastMessageID] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const hasProcessedFirstMessageRef = React.useRef(false);
  const [loading, setLoading] = useState(false);


  // on mount, fetch the first message from localStorage
  // and add it to the messages array
  React.useEffect(() => {
    const firstMessage = localStorage.getItem(`firstMessage-${documentId}`);
    if (firstMessage && !hasProcessedFirstMessageRef.current) {
      const message = {
        id: lastMessageID,
        message: firstMessage,
        senderName: 'Watch My Word',
      };
      setMessages((prevState) => [...prevState, message]);
      setLastMessageID(lastMessageID => lastMessageID + 1);
      hasProcessedFirstMessageRef.current = true;
    }
  }, []); // Updated dependency array to be empty

  const handleSendMessage = (event) => {
    setLoading(true);
    event.preventDefault();
    setMessages((prevState) => {
      const newMessageID = lastMessageID;
      const message = {
        id: newMessageID,
        message: inputValue,
        senderName: 'You',
      };
      setLastMessageID(newMessageID + 1);
      return [...prevState, message];
    });
    axios.post(messageApiUrl, {
      document_id: documentId,
      question: inputValue,
    })
      .then((response) => {
        setLoading(false);
        setMessages((prevState) => {
          const newMessageID = lastMessageID;
          const message = {
            id: response.data.id,
            message: response.data.answer,
            senderName: 'Watch My Word',
          };
          setLastMessageID(newMessageID + 1);
          return [...prevState, message];
        });
      })
      .catch((error) => {
        setLoading(false);
        alert(error)
      });
    setInputValue('');
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Container maxWidth="md">
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', justifyContent: 'center', py: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Chat
      </Typography>
      <Paper sx={{ width: '100%', display: 'flex', flexDirection: 'column', mb: 1 }}>
        {messages.length > 0 &&
          messages.map(msg => (
            <Box key={msg.id} sx={{ textAlign: msg.senderName === 'You' ? 'right' : 'left', m: 1 }}>
              <Typography variant="subtitle2" component="span" fontWeight="bold">
                {msg.senderName}
              </Typography>
              <Typography variant="body2" component="p" sx={{ whiteSpace: 'pre-wrap' }}>
                {msg.message}
              </Typography>
            </Box>
          ))}
      </Paper>
      <Box sx={{ width: '100%', display: 'flex' }}>
        <TextField
          placeholder="Type here..."
          multiline={true}
          fullWidth
          value={inputValue}
          onChange={handleChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !event.shiftKey && !loading) {
              handleSendMessage(event);
            }
          }}
        />
        <IconButton
          color="primary"
          onClick={handleSendMessage}
          // sx={{
          //   position: 'absolute',
          //   bottom: 8, // Adjust the value to align the button vertically as desired
          //   right: 8,  // Adjust the value to align the button horizontally as desired
          // }}
          disabled={loading}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
    </Container>
  );
}

export default Chat;
