import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Box, Button, CircularProgress, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';


const DropzoneBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  transition: 'all 0.2s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
}));

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));


const apiUrl = process.env.REACT_APP_API_URL;
const uploadUrl = `${apiUrl}/api/documents/upload`;
const processUrl = `${apiUrl}/api/documents/process`;

const drop_document_message = "Drag and drop a document here, or click to select a file";

function DocumentUpload() {
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [documentUploadMessage, setDocumentUploadMessage] = useState(drop_document_message);

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    setDocumentUploadMessage("Uploading document...");
    axios.post(
      uploadUrl,
      formData,
      {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(progress);
        },
      }
      )
      .then((response) => {
        const document = response.data;
        processDocument(document.id)
      })
      .catch((error) => {
        setDocumentUploadMessage(drop_document_message)
        if (error.response && error.response.status === 422) {
          alert(`Error: cannot process this type of document`);
        } else {
          alert("Error uploading document");
        }
      });
  }, []);

  const processDocument = (documentId) => {
    setDocumentUploadMessage("Document uploaded succcessfully, analyzing...");
    if (documentId) {
      axios.post(processUrl, { id: documentId })
        .then((response) => {
          setDocumentUploadMessage("Done!");
          const message = response.data.message;
          // store firstMessage in localStorage
          localStorage.setItem(`firstMessage-${documentId}`, message);
          history.push(`/chat/${documentId}`);
        })
        .catch((error) => {
          setDocumentUploadMessage(drop_document_message)
          alert("Error processing document");
        });
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: !(documentUploadMessage === drop_document_message),
    accept: {
      'application/msword': ['.doc', '.docx', '.docm'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.doc', '.docx', '.docm'],
      'application/vnd.ms-word.document.macroEnabled.12': ['.doc', '.docx', '.docm'],
    }
  });

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h1" component="h1">
          Watch My Word
        </Typography>
        <Typography variant="subtitle1" component="h1" gutterBottom>
          Tired of reading through lengthy Word documents? Ask questions to Watch My Word instead.
        </Typography>
        <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 3 }} >
          Document Upload
        </Typography>
        <DropzoneBox {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography variant="body1">Drop the file here ...</Typography>
          ) : (
            <Typography variant="body1">{documentUploadMessage}</Typography>
          )}
          {progress > 0 && progress < 100 && <StyledProgress variant="determinate" value={progress} />}
        </DropzoneBox>
      </Box>
    </Container>
  );
}

export default DocumentUpload;
